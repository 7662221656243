.home-page-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.school-info {
  background-color: #e0e0e0;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: center;
}

.reports-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Расстояние между отчётами */
}

.report-item {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #ddd;
  flex: 1 1 calc(50% - 20px); /* Отчёты занимают 50% ширины */
  box-sizing: border-box;
}

.report-item h3 {
  margin-top: 0;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .report-item {
    flex: 1 1 100%; /* Отчёты занимают 100% ширины на мобильных устройствах */
  }
}