/* CreateReport.css */

.create-report-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .school-info {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #e0e0e0;
    border-radius: 8px;
  }
  
  .school-info p {
    margin: 5px 0;
  }
  
  form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  /* Обеспечиваем одинаковую ширину для всех полей ввода */
  input, .react-datepicker-wrapper, .react-select__control {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box; /* Устанавливаем box-sizing для консистентности размеров */
  }
  
  button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:disabled {
    background-color: #ccc;
  }
  
  .loader {
    margin: 20px 0;
    text-align: center;
    font-size: 18px;
  }
  
  /* Исправление для мобильных устройств */
  @media (max-width: 768px) {
    .create-report-container {
      padding: 15px;
    }
  
    form {
      grid-template-columns: 1fr;
    }
  }
  