/* Основные стили */
body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.App {
  display: flex;
  height: 100vh;
}

.App-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #282c34;
  padding: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1000;
}

.menu-button {
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.sidebar {
  position: fixed;
  left: -100vw; /* Меняем на -100vw, чтобы меню полностью скрывалось за пределами экрана */
  top: 0;
  width: 250px;
  height: 100%;
  background-color: #333;
  transition: left 0.3s;
  padding-top: 60px;
}

.sidebar.open {
  left: 0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  padding: 10px 0;
  text-align: center;
}

.sidebar a {
  display: block;
  color: white;
  text-decoration: none;
  padding: 12px;
  font-size: 18px;
  font-weight: bold;
  background-color: #444;
  border-radius: 8px;
  margin: 5px 20px;
  transition: background-color 0.3s, transform 0.2s;
}

.sidebar a:hover {
  background-color: #555;
  transform: translateY(-3px);
}

.content {
  flex: 1;
  padding: 80px 20px 20px 20px;
  margin-left: 250px;
  transition: margin-left 0.3s;
}

/* Мобильные стили */
@media (max-width: 768px) {
  .sidebar {
    width: 100vw; /* Меняем ширину меню на 100vw */
    left: -100vw; /* Также задаём -100vw, чтобы оно полностью скрывалось */
    height: 100vh;
    padding-top: 60px;
  }

  .sidebar.open {
    left: 0;
    z-index: 2
  }

  .sidebar a {
    font-size: 20px;
    padding: 15px;
    margin: 10px 10px;
  }

  .content {
    padding: 70px 10px 10px 10px;
    margin-left: 0;
  }

  .App-header {
    padding: 0.75rem;
  }

  .menu-button {
    font-size: 26px;
  }
}

@media (max-width: 480px) {
  .sidebar {
    width: 100vw;
    left: -100vw; /* Обеспечиваем скрытие за пределами экрана на мобильных устройствах */
    height: 100vh;
  }

  .sidebar.open {
    left: 0;
    z-index: 2
  }

  .sidebar a {
    font-size: 18px;
    padding: 12px;
    margin: 8px 8px;
  }

  .content {
    padding: 70px 10px 10px 10px;
    margin-left: 0;
  }

  .App-header {
    padding: 0.5rem;
  }

  .menu-button {
    font-size: 24px;
  }
}

.iframe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Высота на весь экран */
  width: 100%;
  position: relative; /* Убедитесь, что позиция относительно контейнера */
}

iframe {
  height: 100vh; /* Высота iframe на весь экран */
  width: calc(100vh * 0.56); /* Ширина подстраивается под высоту, используя соотношение сторон 16:9 */
  max-width: 100%; /* Ограничение ширины */
  border: none;
  z-index: 1; /* Обеспечивает, что iframe будет над другими элементами */
  pointer-events: auto; /* Разрешает взаимодействие */
}

#logo{
  position: absolute;
  right: 3vw;
  width: 6vh;
}